@import "./normalize.css";

.App {
  text-align: center;
}

// General

body {
  font-family: "Noto Sans JP", sans-serif;

  padding: 0 1rem;
  max-width: 120rem;
  margin: 0 auto 2rem;
  text-align: center;
}

p,
ul {
  text-align: left;
}

.text {
  margin-right: auto;
  margin-left: auto;
  max-width: 25rem;
  margin-bottom: 4rem;
}

h1 {
  text-align: center;
  color: #268bd2;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 3rem;
  line-height: 1.1;
}

h2 {
  text-align: center;
  color: #268bd2;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 2rem;
}

h3 {
  color: #268bd2;
}

// Start Page

.select-box {
  display: block;
  color: #268bd2;
  font-size: 1.5rem;
  line-height: 1;
  padding: 0.625rem 0;
  border: #268bd2 0.3vw solid;
  margin: 0.5rem 0;
  user-select: none;

  p {
    display: inline;
    margin: 0;
  }

  // https://css-tricks.com/comparing-various-ways-to-hide-things-in-css/#method-5-the-visually-hidden-class
  input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

.select-box:hover {
  cursor: pointer;
}

.select-box:focus-within {
  border-color: #52d327;
}

.check {
  background-color: #268bd2;
  color: white;
}

.button-styling {
  background-color: #268bd2;
  color: white;
  border-radius: 1rem;
  border: 1px solid #268bd2;
  width: 10rem;
  height: 5rem;
  font-size: 1.5rem;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
  box-shadow: #1d689e 0 0.5rem;
  outline: none;

  &:active {
    box-shadow: #1d689e 0 0.2rem;
    transform: translateY(0.5rem);
  }

  &:focus {
    animation: 1s pulse infinite alternate;
    outline: #1eaafa dotted 2px;
    outline-offset: -7px;
  }
}

.font-choice-dropdown {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.font-choice-dropdown label {
  margin-bottom: 0.5rem;
}

.font-choice-dropdown select {
  font-size: 1.2rem;
  width: 240px;
  color: #268bd2;
  border: 3px solid #268bd2;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0px;
  background: url(./images/down-arrow.png) no-repeat 210px 6px white;
  background-size: 19px 19px;
  padding-left: 60px;
}

@keyframes pulse {
  0% {
    background-color: #7ebbe7;
    border-color: #7ebbe7;
    box-shadow: #e6f6ff 0 0.5rem;
  }

  100% {
    border-color: #268bd2;
    background-color: #268bd2;
    box-shadow: #53a5df 0 0.5rem;
  }
}

// Quiz Page

.quiz {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.quiz-page {
  ul {
    display: table;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 3rem;
  }
}

.card {
  border: #268bd2 0.7vw solid;
  background-color: #268bd2;
  border-radius: 1rem;
  width: 15vw;
  padding: 2vw;
  margin: 1vw;
  font-size: 7vw;
  text-align: center;
  color: white;
}

.focused-card {
  background-color: #1d689e;
  border-color: #1d689e;
  animation: grow 0.5s 1;
  transform: scale(1.1, 1.1);
}

@keyframes grow {
  0% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1.1, 1.1);
  }
}

.card__text {
  margin: 0;
}

.card__text.card__text--combo {
  letter-spacing: -5px;
}

.card__form {
  padding: 0.5rem 0 0.5rem 0;
}

.card__field {
  height: 7vw;
  font-size: 1rem;
  outline: none;
  width: 75%;
  text-align: center;
  text-transform: lowercase;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border-color: white;
  border-style: none;
}

.correct {
  background-color: #52d327;
  border-color: #52d327;
}

.incorrect {
  background-color: #5783a2;
  border-color: #5783a2;
  animation: wrong 1s linear 1;
}

@keyframes wrong {
  0% {
    background-color: #d32727;
    border-color: #d32727;
    transform: translateX(0);
  }

  10% {
    background-color: #d32727;
    border-color: #d32727;
    transform: translateX(3px);
  }

  20% {
    background-color: #d32727;
    border-color: #d32727;
    transform: translateX(-3px);
  }

  30% {
    transform: translateX(3px);
  }

  40% {
    transform: translateX(-3px);
  }

  50% {
    transform: translateX(3px);
  }

  60% {
    transform: translateX(-3px);
  }

  70% {
    transform: translateX(0);
  }

  100% {
    background-color: #5783a2;
    border-color: #5783a2;
  }
}

input[type="text"]:disabled {
  background-color: #e8e8e8;
  color: #d1d1d1;
  -webkit-text-fill-color: #d1d1d1;
}

// Results Page

.results {
  text-align: center;
}

a {
  color: #268bd2;
}

table {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  color: white;
  border-collapse: separate;

  td {
    padding: 0.25rem;
  }

  th {
    padding: 1rem;
    font-size: 7vw;
    background-color: #268bd2;
  }

  a {
    color: black;
    text-decoration: none;
  }
}

.kana-study {
  td {
    padding-bottom: 2rem;
  }
}

.kana-header {
  th {
    border-top: 3px #268bd2 solid;
  }

  th:last-child {
    border-right: 3px #268bd2 solid;
  }

  th:first-child {
    border-left: 3px #268bd2 solid;
  }
}

.kana-details {
  font-size: 3vw;
  color: #268bd2;

  td {
    border-bottom: 3px #268bd2 solid;
  }

  td:last-child {
    border-right: 3px #268bd2 solid;
  }

  td:first-child {
    border-left: 3px #268bd2 solid;
  }
}

.table-section-top {
  h2 {
    display: inline;
    margin-right: 1rem;
    text-transform: capitalize;
  }

  p {
    display: inline;
  }
}

.restart-button-styling {
  background-color: #268bd2;
  color: white;
  border-radius: 1rem;
  border: 1px solid #268bd2;
  width: 10rem;
  height: 5rem;
  font-size: 1.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;

  box-shadow: #1d689e 0 0.5rem;
  outline: none;

  &:active {
    box-shadow: #1d689e 0 0.2rem;
    transform: translateY(0.5rem);
  }

  &:focus {
    animation: 1s pulse infinite alternate;
    outline: #1eaafa dotted 2px;
    outline-offset: -7px;
  }
}

@media only all and (min-width: 70rem) {
  body {
    padding: 0;
  }

  .select-box {
    margin: 0.5rem;
  }

  .incorrect-details {
    display: flex;
    justify-content: center;
  }

  .table-wrap {
    margin-right: 1rem;
  }

  .table-wrap:last-child {
    margin-right: 0;
  }

  .start {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .kana-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0;
    padding: 0;
  }

  .checkbox-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0;
    padding: 0;
  }

  .checkboxes {
    display: grid;
  }

  .main {
    grid-template-columns: 1fr 1fr;
  }

  .combo {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 1.5rem;
    font-size: 2rem;
  }

  .card {
    border: #268bd2 0.3rem solid;
    border-radius: 1rem;
    width: 8rem;
    padding: 1rem;
    margin: 0.5rem;
    font-size: 4rem;
    text-align: center;
  }

  .focused-card {
    border-color: #1d689e;
  }

  .card__field {
    height: 4rem;
    font-size: 2rem;
    width: 90%;
  }

  .card__text.combo {
    letter-spacing: -9px;
  }

  .correct {
    border-color: #52d327;
  }

  .incorrect {
    border-color: #5783a2;
  }

  table {
    width: 100%;

    th {
      font-size: 2vw;
      width: 2vw;
    }

    td {
      font-size: 1vw;
    }
  }

  .kana-details {
    font-size: 1rem;
  }
}
